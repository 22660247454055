import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import store, { AppDispatch, RootState } from '~/redux/store';
import { Capture, OSKGeoJson } from 'oskcore';
import { connect } from 'react-redux';
import { useMap } from 'react-leaflet';
import { useTheme } from 'styled-components';
import { OSKThemeType, useRefState } from 'oskcomponents';
import gdux, { GDUX_HIGHLIGHT_ADD, GDUX_HIGHLIGHT_REMOVE, GDUX_SELECT_ADD, GDUX_SELECT_REMOVE } from '~/gdux';
import { dequeueFile, enqueueFile } from '~/redux/modules/data/cart';
import { utcToZonedTime } from 'date-fns-tz';

type LeafletFootprintsProps = {
    /** From redux, a list of the captures that were returned in a search*/
    captures?: Capture[];
    /** From redux, the currently selected aoi on the map */
    selectedAoi?: OSKGeoJson;
    /** From redux, an optional start date to filter results by. */
    filterStartDate?: Date;
    /** From redux, an optional end date to filter results by. */
    filterEndDate?: Date;
    /** The method to invoke when a footprint is clicked */
    onFootprintSelected?: (file: Capture) => void;
};

const LeafletFootprints = ({
    captures,
    selectedAoi,
    filterStartDate,
    filterEndDate,
    onFootprintSelected,
}: LeafletFootprintsProps) => {
    const map = useMap();
    const theme = useTheme() as OSKThemeType;

    const capturesLayer = useRef<L.LayerGroup>();
    const aoiLayer = useRef<L.LayerGroup>();
    const footprints = useRef<Record<string, L.Polygon>>({});
    const highlightedFootprints = useRef<Record<string, L.Polygon>>({});
    const selectedFootprints = useRef<Record<string, L.Polygon>>({});

    const [getHighlighted, setHighlighted] = useRefState<string[]>([]);
    const [getSelected, setSelected] = useRefState<string[]>([]);

    const InitLayer = (layer: React.MutableRefObject<L.LayerGroup<any> | undefined>) => {
        if (layer.current) {
            map.removeLayer(layer.current);
        }

        layer.current = L.layerGroup();
        layer.current.addTo(map);
    };

    const AddHighlight = (id: string) => {
        if (!id) return;

        if (!getHighlighted().includes(id)) {
            setHighlighted([...getHighlighted(), id]);
        }
    };

    const RemoveHighlight = (id: string) => {
        if (!id) return;

        if (getHighlighted().includes(id)) {
            setHighlighted(getHighlighted().filter((h) => h !== id));
        }
    };

    const AddSelection = (id: string) => {
        if (!id) return;

        if (!getSelected().includes(id)) {
            setSelected([...getSelected(), id]);
        }
    };

    const RemoveSelection = (id: string) => {
        if (!id) return;

        if (getSelected().includes(id)) {
            setSelected(getSelected().filter((s) => s !== id));
        }
    };

    useEffect(() => {
        InitLayer(capturesLayer);
        InitLayer(aoiLayer);

        map.createPane('footprints');

        gdux.subscribe(GDUX_HIGHLIGHT_ADD, AddHighlight);
        gdux.subscribe(GDUX_HIGHLIGHT_REMOVE, RemoveHighlight);
        gdux.subscribe(GDUX_SELECT_ADD, AddSelection);
        gdux.subscribe(GDUX_SELECT_REMOVE, RemoveSelection);

        return () => {
            gdux.unsubscribe(GDUX_HIGHLIGHT_ADD, AddHighlight);
            gdux.unsubscribe(GDUX_HIGHLIGHT_REMOVE, RemoveHighlight);
            gdux.unsubscribe(GDUX_SELECT_ADD, AddSelection);
            gdux.unsubscribe(GDUX_SELECT_REMOVE, RemoveSelection);
        };
    }, []);

    useEffect(() => {
        // Clear footprints when the captures update
        capturesLayer.current?.clearLayers();
    }, [captures]);

    useEffect(() => {
        // Regenerate the footprints if there's a change in the number
        if (capturesLayer.current?.getLayers().length !== captures?.length) {
            captures?.forEach((capture) => {
                if (capture.footprint && 'coordinates' in capture.footprint) {
                    const points = OSKGeoJson.fromAPIGeometry(capture.footprint).toLeafletCoordinates();
                    if (capturesLayer.current) {
                        // Preserve the color if they were previously highlighted
                        const color = Object.keys(selectedFootprints.current).includes(capture.id)
                            ? theme.colors.accent
                            : theme.colors.cyan1a;
                        const area = L.polygon(points, {
                            color,
                            interactive: true,
                            pane: 'footprints',
                        });

                        area.on('mouseover', () => gdux.trigger(GDUX_HIGHLIGHT_ADD, capture.id));
                        area.on('mouseout', () => gdux.trigger(GDUX_HIGHLIGHT_REMOVE, capture.id));
                        area.on('click', () => {
                            if (getSelected().includes(capture.id)) {
                                gdux.trigger(GDUX_SELECT_REMOVE, capture.id);
                            } else {
                                gdux.trigger(GDUX_SELECT_ADD, capture.id);
                            }

                            onFootprintSelected && onFootprintSelected(capture);
                        });

                        footprints.current[capture.id] = area;
                        capturesLayer.current.addLayer(area);
                    }
                }
            });

            // Finally, if we now have a footprints pane, make sure
            // pointer events are enabled.
            const footprintsPane = map.getPane('footprints');
            if (footprintsPane?.firstChild) {
                // @ts-ignore
                (footprintsPane.firstChild as HTMLElement).removeAttribute('pointer-events');
            }
        }
    }, [captures, filterStartDate, filterEndDate]);

    useEffect(() => {
        Object.values([
            ...Object.keys(highlightedFootprints.current),
            ...Object.keys(selectedFootprints.current),
        ]).forEach((id) => {
            const footprint = footprints.current[id];
            footprint?.setStyle({ color: theme.colors.cyan1a });
        });

        getSelected().forEach((id) => {
            const footprint = footprints.current[id];
            footprint?.setStyle({ color: theme.colors.accent });
            selectedFootprints.current[id] = footprint;
        });

        getHighlighted().forEach((id) => {
            const footprint = footprints.current[id];
            footprint?.setStyle({ color: theme.colors.white });
            highlightedFootprints.current[id] = footprint;
        });
    }, [getSelected(), getHighlighted()]);

    useEffect(() => {
        aoiLayer.current?.clearLayers();

        if (selectedAoi && selectedAoi.features.length > 0) {
            switch (selectedAoi.features[0].type) {
                case 'Polygon':
                    const area = L.polygon(selectedAoi.toLeafletCoordinates(), { interactive: true });
                    aoiLayer.current?.addLayer(area);
                    break;
                case 'Point':
                    const mark = L.marker(selectedAoi.toLeafletCoordinates()[0], { interactive: true });
                    aoiLayer.current?.addLayer(mark);
                    break;
                case 'MultiPoint':
                    const coords = selectedAoi.toLeafletCoordinates();
                    selectedAoi.features.forEach((feature, idx) => {
                        const mark = L.marker(coords[idx], { interactive: true });
                        aoiLayer.current?.addLayer(mark);
                    });
                    break;
            }
        }
    }, [selectedAoi]);

    return <React.Fragment />;
};

const mapStateToProps = (state: RootState) => {
    const { results, roi, filterStartDate, filterEndDate } = state.data.search;

    const oldestTimes: { [id: string]: Date } = {};

    results?.forEach((capture: Capture) => {
        const utcTime = utcToZonedTime(new Date(capture.acquisition_time), 'UTC');

        if (!(capture.task_id in oldestTimes)) oldestTimes[capture.task_id] = utcTime;

        if (oldestTimes[capture.task_id] > utcTime) oldestTimes[capture.task_id] = utcTime;
    });

    const filteredResults = results?.filter((capture: Capture) => {
        if (filterStartDate && oldestTimes[capture.task_id] < filterStartDate) return false;
        if (filterEndDate && oldestTimes[capture.task_id] > filterEndDate) return false;
        return true;
    });

    return {
        captures: filteredResults,
        selectedAoi: roi,
        filterStartDate,
        filterEndDate,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onFootprintSelected: (file: Capture) => {
            const { id: fileId, task_id: taskId } = file;
            if (store.getState().data.cart.enqueued[fileId]) {
                dispatch(dequeueFile(fileId));
            } else {
                dispatch(enqueueFile({ fileId, taskId }, file));
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeafletFootprints);
