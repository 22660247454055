import React, { useEffect, useRef, useState } from 'react';
import { fromArrayBuffer } from 'geotiff';
import { DefaultProgram, webglSupported } from 'spectra-gl';
import { fetchArtifact, spectraGLEngine } from '~/utils';
import { Box, Spinner } from 'oskcomponents';
import { parseTiff } from '~/utils/tiffParser';
import { findCenterOffset } from '~/utils/imageUtils';
import CSS from 'csstype';
import { generateRenderConfig } from 'spectra-gl/src/engine';

const browserSupported = webglSupported();

export type ImgTiffLoadingStyle = 'lazy' | 'eager';
export type ImgTiffProps = {
    /** The source of the image */
    src: string;
    /** The width of the resulting canvas */
    width?: number;
    /** The height of the resulting canvas */
    height?: number;
    /** A style to apply to the canvas or image that is resulting */
    style?: CSS.Properties;
    /**
     * If lazy, wait for the component to be scrolled into the viewport.
     * If lazy_double, wait for the component to be rendered twice
     * which is useful if the component starts off in a hidden state.
     * If eager, render immediately
     */
    loading?: ImgTiffLoadingStyle;
} & JSX.IntrinsicElements['canvas'];

export const ImgTiff = React.forwardRef<HTMLDivElement, ImgTiffProps>(
    ({ src, width, style, height, loading, ...props }: ImgTiffProps, forwardRef) => {
        const ref = useRef<HTMLDivElement>(null);
        const canvasRef = useRef<HTMLCanvasElement>(null);

        // Start off as visible if there's no loading directive or if we just want eager behavior
        const [error, setError] = useState(false);
        const [loaded, setLoaded] = useState(false);
        const [visible, setVisible] = useState(loading === undefined || loading === 'eager');

        // This hook will wait for the image to be scrolled into the viewport
        // and once it is present, it will set visible to true.
        useEffect(() => {
            if (!visible) {
                const io = new IntersectionObserver(function (entries) {
                    for (const entry of entries) {
                        if (entry.intersectionRatio > 0) {
                            setVisible(true);
                        }
                    }
                });

                if (ref.current) {
                    io.observe(ref.current);
                }

                return () => {
                    io.disconnect();
                };
            }
        }, [visible]);

        useEffect(() => {
            if (visible && browserSupported) {
                fetchArtifact(src)
                    .then((resp) => resp.arrayBuffer())
                    .then(async (buf) => {
                        if (canvasRef.current) {
                            const geotiff = await fromArrayBuffer(buf);
                            const image = await geotiff.getImage();
                            const raster = await parseTiff(new Uint8Array(buf), image);
                            const bitmaps = await spectraGLEngine.withProgram(DefaultProgram).process(
                                [
                                    {
                                        width: image.getWidth(),
                                        height: image.getHeight(),
                                        samples: image.getSamplesPerPixel(),
                                        bbox: image.getBoundingBox(),
                                        raster_bytes: raster as Uint8Array,
                                    },
                                ],
                                generateRenderConfig({
                                    mode: 'rgb',
                                    enable_transparency_mask: false, // We want an opaque background in this case.
                                }),
                            );

                            if (bitmaps.length === 1 && bitmaps[0]) {
                                const canvas = canvasRef.current;
                                const ctx = canvas.getContext('2d');
                                if (ctx) {
                                    if (width && height) {
                                        // Calculate the offset to render the image
                                        // such that it's mostly centered.
                                        const offset = findCenterOffset(bitmaps[0].raster, width, height);

                                        // Fill the thumbnail will black
                                        ctx.fillStyle = '#000000';
                                        ctx.fillRect(0, 0, width, height);
                                        // Render the image, applying the center offset.
                                        ctx.drawImage(
                                            bitmaps[0].raster,
                                            offset.x,
                                            offset.y,
                                            width,
                                            height,
                                            0,
                                            0,
                                            width,
                                            height,
                                        );
                                    } else {
                                        ctx.drawImage(bitmaps[0].raster, 0, 0);
                                    }
                                } else {
                                    console.warn('Failed to render GeoTIFF because the canvas was null');
                                }
                            } else {
                                console.warn(`SpectraGL Failed to render ${src} as an rgb preview image.`);
                            }
                        }
                    })
                    .catch((ex) => {
                        console.error(ex);
                        setError(true);
                    })
                    .finally(() => {
                        setLoaded(true);
                    });
            } else if (visible) {
                // Browser not supported! We should handle this.
            }
        }, [src, canvasRef, visible]);

        return (
            <Box
                ref={(node) => {
                    // @ts-ignore
                    ref.current = node;
                    forwardRef = ref;
                }}
            >
                {(!browserSupported || error) && visible && (
                    <img
                        src="/images/image-failed.png"
                        title="Failed to process the tiff imagery"
                        width={width}
                        height={height}
                        style={style}
                    />
                )}
                {browserSupported && !error && !loaded && (
                    <Box w={width} h={height} center="all">
                        <Spinner
                            variant="Circle"
                            style={{
                                width: '50%',
                                height: '50%',
                            }}
                        />
                    </Box>
                )}
                {browserSupported && !error && visible && (
                    <canvas
                        ref={canvasRef}
                        style={{
                            display: loaded ? 'block' : 'none',
                            borderRadius: '7px',
                            cursor: props.onClick ? 'pointer' : 'default',
                            ...style,
                        }}
                        width={width}
                        height={height}
                        {...props}
                    />
                )}
            </Box>
        );
    },
);
