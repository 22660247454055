import type {
    GLProgram,
    CompiledGLProgram,
    Raster,
    SpectraGLOutput,
    RenderConfig,
    RasterWithOverlay,
} from './lib/models';
import { Object2D, Rect2D } from './lib/object2d';
import { createShader, createProgram, webglSupported } from './lib/webgl_utils';
import { color_rainbow, generateGradient } from './lib/gradients';
import { DefaultProgram } from '.';

/**
 * Generates the default values for a Renderconfig object

 * @param overrides The configuration options specific to your invocation
 * @returns A RenderConfig to be passed into SpectraGL, with default options configured
 */
export function generateRenderConfig(overrides: Partial<RenderConfig>): RenderConfig {
    return {
        mode: 'rgb',
        blending_depth: 10,
        gradient: color_rainbow,
        contrast: 1.0,
        brightness: 0.0,
        gamma: 1.2,
        opacity: 1.0,
        enable_transparency_mask: true,
        ...overrides,
    } as RenderConfig;
}

/**
 * SpectraGLEngine is the primary entrypoint for rendering and processing geotiff files.
 */
export class SpectraGLEngine {
    /** The main webgl canvas. */
    private canvas?: OffscreenCanvas;
    /** The rendering context */
    private gl?: WebGLRenderingContext | null;
    /** The compiled program */
    private program?: CompiledGLProgram | null;
    /** If true, the engine is initialized */
    private initialized = false;

    /**
     * Create a new instance of the SpectraGLEngine which is capable of processing raster imagery.
     */
    constructor(program?: GLProgram) {
        if (!webglSupported()) {
            console.warn('Failed to initialize SpectraGL because webgl is not supported on this browser');
            return;
        }

        // Check for OffscreenCanvas support and use it if applicable
        if ('OffscreenCanvas' in globalThis) {
            this.canvas = new OffscreenCanvas(1, 1);
            this.gl = this.canvas.getContext('webgl');
            this.withProgram(program ?? DefaultProgram);
            this.initialized = true;
        } else {
            console.warn('OffscreenCanvas is unsupported!');
        }
    }

    withProgram(program: GLProgram): SpectraGLEngine {
        const { gl } = this;
        if (gl) {
            this.program = this.loadProgram(gl, program);
        }
        return this;
    }

    /**
     * Given a set of rasters and a configuration, apply the relevant bitmap transformations
     * and return the underlying ImageBitmap object for each image.
     *
     * @param rasters An array of raster imagery.
     * @param config The configuration object describing how the image will be manipulated.
     * @returns A Promise that resolves with all the processed ImageBitmap objects.
     */
    process(rasters: (Raster | RasterWithOverlay)[], config: RenderConfig): Promise<SpectraGLOutput[]> {
        // Configure the options
        const gradient = generateGradient(config.gradient, config.blending_depth);

        return new Promise((resolve) => {
            const results: SpectraGLOutput[] = [];
            for (const raster of rasters) {
                let obj = Rect2D()
                    .withGradient(gradient)
                    .withRaster(raster.samples, raster.width, raster.height, raster.raster_bytes);

                // If the supplied raster is a RasterWithOverlay, we'll copy
                // the extra properties into the object to be rendered.
                if ('overlay_bytes' in raster) {
                    obj = obj.withOverlay(raster.overlay_bytes);
                }

                const bitmap = this.render(obj, raster.width, raster.height, config);
                if (bitmap) {
                    const result: SpectraGLOutput = {
                        raster: bitmap,
                        width: raster.width,
                        height: raster.height,
                    };

                    // Copy the raster properties, minus some attributes
                    const omit = ['width', 'height', 'bytes'];
                    for (const key in raster) {
                        if (omit.includes(key)) continue;
                        result[key] = raster[key];
                    }

                    // Return the final processed result
                    results.push(result);
                }
            }
            resolve(results);
        });
    }

    /**
     * This will take a GLProgram request, compile it, and load the resulting program into the OffscreenCanvas
     * used by the SpectraGLEngine library.
     */
    private loadProgram(gl: WebGLRenderingContext, programRequest: GLProgram): CompiledGLProgram | null {
        const { vertexShader, fragmentShader } = programRequest;
        const compiledVertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShader);
        const compiledFragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShader);

        if (compiledVertexShader && compiledFragmentShader) {
            const program = createProgram(gl, compiledVertexShader, compiledFragmentShader);
            if (program) {
                gl.useProgram(program);

                return {
                    ...programRequest,
                    compiledProgram: program,
                };
            }
        }

        console.error('Failed to load WebGL program');
        return null;
    }

    /**
     * This will render a single image to the OffscreenCanvas and return the resulting ImageBitmap.
     */
    private render(object: Object2D, width: number, height: number, config: RenderConfig): ImageBitmap | null {
        if (!this.canvas) return null;
        if (!this.gl) return null;
        if (!this.program) return null;
        if (!this.initialized) return null;

        const { gl } = this;
        const { attributes, uniforms, compiledProgram, beforeDraw } = this.program;

        if (gl.canvas.width != width || gl.canvas.height != height) {
            gl.canvas.width = width;
            gl.canvas.height = height;
            gl.viewport(0, 0, width, height);
        }

        // Resources to later release
        const buffersToRelease = [];

        for (const attributeName in attributes) {
            const method = attributes[attributeName];
            const loc = gl.getAttribLocation(compiledProgram, attributeName);

            if (loc >= 0) {
                const buffer = gl.createBuffer();
                gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
                gl.bufferData(gl.ARRAY_BUFFER, method(gl, this, loc, object, config), gl.STATIC_DRAW);
                gl.enableVertexAttribArray(loc);
                buffersToRelease.push(buffer);
            } else {
                console.error(`Failed to locate attribute ${attributeName}`);
            }
        }

        for (const uniformName in uniforms) {
            const method = uniforms[uniformName];
            const loc = gl.getUniformLocation(compiledProgram, uniformName);
            if (loc) {
                method(gl, this, loc, object, config);
            } else {
                console.error(`Failed to locate uniform ${uniformName}`);
            }
        }

        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.blendFunc(gl.SRC_ALPHA_SATURATE, gl.SRC_ALPHA);
        gl.disable(gl.DEPTH_TEST);
        gl.enable(gl.BLEND);

        // Invoke beforeDraw if it exists
        if (beforeDraw) {
            beforeDraw(gl);
        }

        const primitiveType = gl.TRIANGLES;
        const offset = 0;
        const count = object.vertexes.length / 2;
        gl.drawArrays(primitiveType, offset, count);

        // Release resources
        for (const buffer of buffersToRelease) {
            gl.deleteBuffer(buffer);
        }

        return this.canvas.transferToImageBitmap();
    }
}
