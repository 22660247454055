import { useMap } from '~/hooks';
import { RootState } from '~/redux/store';
import { Capture, Color, noop } from 'oskcore';
import { connect, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { fetchArtifact, spectraGLEngine } from '~/utils';
import { GeoTIFFImage, fromArrayBuffer } from 'geotiff';
import { DefaultProgram, RGBWithOverlayProgram } from 'spectra-gl';
import { parseTiff } from '~/utils/tiffParser';

import _ from 'lodash';
import L from 'leaflet';
import { FootprintOverlayMode, allTaskOverlayModes } from '~/redux/modules/data/search';
import { GLProgram, RenderMode } from 'spectra-gl/src/lib/models';
import { generateRenderConfig } from 'spectra-gl/src/engine';

// @ts-ignore

const color_cloudmap: Array<Color> = [
    [0, 0, 0],
    [0, 255, 0],
    [255, 255, 0],
];

export const CanvasOverlay = L.ImageOverlay.extend({
    _initImage() {
        const el = (this._image = this._url);

        el.classList.add('leaflet-image-layer');
        if (this._zoomAnimated) {
            el.classList.add('leaflet-zoom-animated');
        }
    },
});

// @ts-ignore
export function canvasOverlay(el, bounds, options) {
    // @ts-ignore
    return new CanvasOverlay(el, bounds, options);
}

type FootprintOverlayProps = {
    /** From redux, a list of the captures that were returned in a search*/
    captures?: Capture[];

    /** From redux, a map of overlay modes by task id. */
    overlayModes?: Record<string, FootprintOverlayMode>;

    /** From redux, map of cloud artifact urls for a given file_id. */
    cloudOverlayMap?: Record<string, string>;
};

/** Represents a cache of rendered overlays and supporting data,
 *  sorted first by capture_id */
type TiffCache = {
    [capture_id: string]: TiffOverlayCache;
};

/** And further sorted by overlay mode */
type TiffOverlayCache = {
    [M in FootprintOverlayMode]: PreRenderedTiffs;
};

type PreRenderedTiffs = {
    /** The rendered image.
     * (This is never cleared from cache.) */
    bitmap?: ImageBitmap;
    /** The image object.
     * (This is never cleared from cache.) */
    image?: GeoTIFFImage;
    /** The HTML5 canvas we used to render it.
     * (This is regenerated each time due to DOM confusion.) */
    canvas?: HTMLCanvasElement;
    /** The map layer the canvas was added to. */
    layer?: any; // Map layer
};

export const FootprintImageOverlays = ({
    captures,
    overlayModes,
    cloudOverlayMap,
    ...props
}: FootprintOverlayProps) => {
    const map = useMap();
    const tiffCache = useRef<TiffCache>({});

    const modeUpdated = useSelector(allTaskOverlayModes, _.isEqual);

    /** Clears the overlay data for a particular capture. */
    const _clearCaptureOverlay = (data: PreRenderedTiffs) => {
        /* Note: we don't clear the bitmap or image data
            to avoid re-rendering */

        if (data.canvas) {
            data.canvas?.remove();
            data.canvas = undefined;
        }

        if (data.layer) {
            map.removeLayer(data.layer);
            data.layer = undefined;
        }
    };

    const ClearMap = (capture_id?: string, overlayMode?: FootprintOverlayMode) => {
        // Remove / clean up from the DOM, but don't remove from the cache.
        if (!capture_id) {
            Object.values(tiffCache.current).forEach((taskCache) => {
                if (overlayMode) {
                    _clearCaptureOverlay(taskCache[overlayMode]);
                } else {
                    Object.values(taskCache).forEach((cache) => _clearCaptureOverlay(cache));
                }
            });
        } else if (capture_id) {
            if (overlayMode) {
                _clearCaptureOverlay(tiffCache.current[capture_id][overlayMode]);
            } else {
                Object.values(tiffCache.current[capture_id]).forEach((cache) => _clearCaptureOverlay(cache));
            }
        }
    };

    // Clear cache on new search
    // TODO: Only clear out captures that are no longer in the results.
    useEffect(() => {
        if (captures) {
            ClearMap();
            tiffCache.current = {} as TiffCache;
        }
    }, [captures]);

    useEffect(() => {
        if (captures) {
            captures?.forEach((capture) => {
                const overlayMode: FootprintOverlayMode = overlayModes
                    ? overlayModes[capture.task_id] ?? 'none'
                    : 'none';
                if (!capture || !capture.preview_artifact) return;

                // Don't render if overlay mode for this task is 'none'
                if (overlayMode === 'none') return;

                // Prepare cache if not initialized
                let bitmap: ImageBitmap | undefined, layer: any | undefined, image: GeoTIFFImage | undefined;

                if (!(capture.id in tiffCache.current)) {
                    tiffCache.current[capture.id] = {} as TiffOverlayCache;
                }
                if (!(overlayMode in tiffCache.current[capture.id])) {
                    tiffCache.current[capture.id][overlayMode] = {} as PreRenderedTiffs;
                }

                // Load from cache if available
                const cache = tiffCache.current[capture.id][overlayMode];
                if (['bitmap', 'canvas', 'layer'].every((p) => p in cache)) {
                    ({ bitmap, layer, image } = cache);

                    // We have to create a new canvas every time because the DOM recognizes
                    // "reused" canvas elements as a duplicate of the original.
                    const canvas = document.createElement('canvas');

                    if (image && !layer) {
                        const [gx1, gy1, gx2, gy2] = image.getBoundingBox();
                        const corners = [new L.LatLng(gy1, gx1), new L.LatLng(gy2, gx2)];
                        const bounds = new L.LatLngBounds(corners[1], corners[0]);

                        layer = canvasOverlay(canvas, bounds, {});
                    }

                    if (bitmap && layer) {
                        canvas.width = bitmap.width;
                        canvas.height = bitmap.height;
                        canvas.style.imageRendering = 'crisp-edges'; // Nearest-neighbor upscaling

                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            ctx.drawImage(bitmap, 0, 0);
                            layer.addTo(map);
                        }

                        tiffCache.current[capture.id][overlayMode].canvas = canvas;
                    }
                } else {
                    // Otherwise, generate the image
                    const cached_cloud_cover_map_artifact =
                        cloudOverlayMap && capture.id in cloudOverlayMap ? cloudOverlayMap[capture.id] : undefined;
                    fetchArtifact(capture.preview_artifact ?? '')
                        .then((resp) => resp.arrayBuffer())
                        .then(async (buf) => {
                            const geotiff = await fromArrayBuffer(buf);
                            const image = await geotiff.getImage();
                            const raster = await parseTiff(new Uint8Array(buf), image);

                            let overlayRaster: Uint8Array = {} as Uint8Array;
                            if (cached_cloud_cover_map_artifact) {
                                const cloudMapBuf = await fetchArtifact(cached_cloud_cover_map_artifact ?? '').then(
                                    (resp) => resp.arrayBuffer(),
                                );
                                const cloudMapTiff = await fromArrayBuffer(cloudMapBuf);
                                const cloudImage = await cloudMapTiff.getImage();
                                overlayRaster = await parseTiff(new Uint8Array(cloudMapBuf), cloudImage);
                            }
                            const [gx1, gy1, gx2, gy2] = image.getBoundingBox();
                            const corners = [new L.LatLng(gy1, gx1), new L.LatLng(gy2, gx2)];
                            const bounds = new L.LatLngBounds(corners[1], corners[0]);

                            let program: GLProgram = DefaultProgram;
                            let mode: RenderMode = 'rgb';
                            if (overlayMode === 'clouds' && cached_cloud_cover_map_artifact) {
                                program = RGBWithOverlayProgram;
                                mode = 'gradient';
                            }

                            const spectraGLBitmaps = await spectraGLEngine.withProgram(program).process(
                                [
                                    {
                                        width: image.getWidth(),
                                        height: image.getHeight(),
                                        gx1,
                                        gy1,
                                        gx2,
                                        gy2,
                                        bounds,
                                        samples: image.getBytesPerPixel(),
                                        raster_bytes: raster as Uint8Array,
                                        overlay_bytes: overlayRaster as Uint8Array,
                                    },
                                ],
                                generateRenderConfig({
                                    mode,
                                    gradient: color_cloudmap,
                                    blending_depth: 1,
                                }),
                            );

                            const canvas = document.createElement('canvas');
                            canvas.width = image.getWidth();
                            canvas.height = image.getHeight();
                            canvas.style.imageRendering = 'crisp-edges'; // Nearest-neighbor upscaling

                            const ctx = canvas.getContext('2d');
                            const bitmap = spectraGLBitmaps[0];
                            if (ctx) ctx.drawImage(bitmap.raster, 0, 0);

                            const overlay = canvasOverlay(canvas, bounds, {});
                            overlay.addTo(map);

                            tiffCache.current[capture.id][overlayMode].canvas = canvas;
                            tiffCache.current[capture.id][overlayMode].layer = overlay;
                            tiffCache.current[capture.id][overlayMode].bitmap = bitmap.raster;
                            tiffCache.current[capture.id][overlayMode].image = image;
                        })
                        .then(() => {
                            // Check if overlay mode has changed since we started generating.
                            const newOverlayMode: FootprintOverlayMode = overlayModes
                                ? overlayModes[capture.task_id] ?? 'none'
                                : 'none';

                            // If so, clear the old mode.
                            if (overlayMode !== newOverlayMode) {
                                ClearMap(capture.id, overlayMode);
                            }
                        });
                }
            });
        }

        return () => ClearMap();
    }, [captures, modeUpdated]);

    return null;
};

const mapStateToProps = (state: RootState) => {
    const { results, taskOverlayModes, cloudOverlayMap } = state.data.search;

    return {
        captures: results,
        overlayModes: taskOverlayModes,
        cloudOverlayMap,
    };
};

export default connect(mapStateToProps, noop)(FootprintImageOverlays);
