import type { GLProgram } from '../lib/models';

const vertexShader = `
    attribute vec4 a_position;
    attribute vec2 a_texcoord;
    varying vec2 v_texcoord;

    void main() {
        gl_Position = a_position;
        v_texcoord = a_texcoord;
    }
`;

const fragmentShader = `
    precision mediump float;
    varying vec2 v_texcoord;
    
    uniform sampler2D u_gradient;
    uniform sampler2D u_texture;
    uniform bool u_enable_gradient;
    uniform bool u_enable_transparency_mask;
    uniform float u_opacity;
    uniform float u_contrast;
    uniform float u_brightness;
    uniform float u_gamma;

    float contrast(float pixel) {
        return u_contrast * (pixel - 0.5) + 0.5 + u_brightness;
    }

    float gamma(float pixel, float gain) {
        return pow(pixel, gain);
    }

    void main() {
        vec4 texture = texture2D(u_texture, v_texcoord);
        float pixel = (texture.r + texture.g + texture.b) / 3.0;

        if (u_enable_gradient) {
            vec4 gradient = texture2D(u_gradient, vec2(pixel, 0.0));
            gl_FragColor = vec4(
                gamma(contrast(gradient.r), u_gamma), 
                gamma(contrast(gradient.g), u_gamma), 
                gamma(contrast(gradient.b), u_gamma), 
                u_opacity
            );
        } else {
            gl_FragColor = vec4(
                gamma(contrast(texture.r), u_gamma),
                gamma(contrast(texture.g), u_gamma),
                gamma(contrast(texture.b), u_gamma),
                u_opacity
            );
        }
        
        if (pixel == 0.0 && u_enable_transparency_mask) {
            gl_FragColor.a = 0.0;
        } 

    }
`;

export const DefaultProgram: GLProgram = {
    vertexShader,
    fragmentShader,
    attributes: {
        a_position: (gl, engine, loc, obj) => {
            gl.vertexAttribPointer(loc, 2, gl.FLOAT, false, 0, 0);
            return new Float32Array(obj.vertexes);
        },
        a_texcoord: (gl, engine, loc, obj) => {
            gl.vertexAttribPointer(loc, 2, gl.FLOAT, false, 0, 0);
            return new Float32Array(obj.texcoords);
        },
    },
    uniforms: {
        u_enable_gradient: (gl, engine, loc, obj, config) => {
            let mode = 0;
            if ('mode' in config) {
                mode = config.mode === 'gradient' ? 1 : 0;
            }

            gl.uniform1i(loc, mode);
        },
        u_opacity: (gl, engine, loc, obj, config) => {
            gl.uniform1f(loc, config.opacity);
        },
        u_contrast: (gl, engine, loc, obj, config) => {
            gl.uniform1f(loc, config.contrast);
        },
        u_brightness: (gl, engine, loc, obj, config) => {
            gl.uniform1f(loc, config.brightness);
        },
        u_gamma: (gl, engine, loc, obj, config) => {
            gl.uniform1f(loc, config.gamma);
        },
        u_enable_transparency_mask: (gl, engine, loc, obj, config) => {
            gl.uniform1i(loc, config.enable_transparency_mask ? 1 : 0);
        },
        u_texture: (gl, engine, loc, obj) => {
            if (obj.textureRaster) {
                // Create a texture.
                const texture = gl.createTexture();
                gl.uniform1i(loc, 0);
                gl.activeTexture(gl.TEXTURE0);
                gl.bindTexture(gl.TEXTURE_2D, texture);

                // Determine rgb vs. luminance
                // RGB = 3 channels per pixel
                // Luminance = 1 channel per pixel
                const imageFormat = obj.textureChannels === 3 ? gl.RGB : gl.LUMINANCE;

                gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
                gl.texImage2D(
                    gl.TEXTURE_2D,
                    0,
                    imageFormat,
                    obj.textureWidth,
                    obj.textureHeight,
                    0,
                    imageFormat,
                    gl.UNSIGNED_BYTE,
                    new Uint8Array(obj.textureRaster),
                );

                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            }
        },
        u_gradient: (gl, engine, loc, obj) => {
            if (obj.textureGradient) {
                const texture = gl.createTexture();

                gl.uniform1i(loc, 1);
                gl.activeTexture(gl.TEXTURE1);
                gl.bindTexture(gl.TEXTURE_2D, texture);
                gl.texImage2D(
                    gl.TEXTURE_2D,
                    0,
                    gl.RGB,
                    obj.textureGradient.length / 3,
                    1,
                    0,
                    gl.RGB,
                    gl.UNSIGNED_BYTE,
                    new Uint8Array(obj.textureGradient),
                );

                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                // Use nearest neighbor because we interpolate the gradient manually. This gives us
                // more control. Some products (like NDVI) shouldn't have any gradiation at all.
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
            } else {
                console.error('No gradient specified');
            }
        },
    },
};

export default DefaultProgram;
